<template>
  <div class="my-4">
    <b-container>
      <b-row class="mb-4">
        <b-col>
          <b-card>
              <span>
                <b-form inline class="mb-4">
                  <label class="mr-2" for="dateInputStart">Start date:</label>
                  <b-form-datepicker id="dateInputStart" class="mr-2" type="date" v-model="newsRiskStartDate" required> </b-form-datepicker>
                  <label class="mr-2" for="dateInputEnd">End date:</label>
                  <b-form-datepicker id="dateInputEnd" class="mr-2" type="date" v-model="newsRiskEndDate" required> </b-form-datepicker>
                  <b-button variant="primary" v-on:click="changeDate">Apply</b-button>
                </b-form>
                <b-form inline class="mb-4">
                  <label class="mr-2">How many items to show (type 0 for all):</label>
                  <b-form-input class="mr-2" type="number" v-model="amount"> </b-form-input>
                </b-form>
              </span>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card class="mb-4">
            <span>
              <div v-if="!loadingNewsRisk" class="mb-4">
                <h4>Most active topics based on KPMG Insights</h4>
                <div v-for="item in newsShow" :key="'newsRisk' + item.name">
                  <div class="mb-2">
                    <div>{{ item.name }} - {{ item.count }}</div>
                  </div>
                </div>
              </div>
              <span v-if="loadingNewsRisk">Loading topics... <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </span>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mb-4">
          <b-card>
            <div id="countrieschart" style="width: 100%; height: 400px;"></div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mb-4">
          <b-card>
            <div id="treemap" style="width: 100%; height: 600px;"></div>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <b-card>
            <div id="martrendchart" style="width: 100%; height: 1000px;"></div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import * as echarts from 'echarts'
const theme = { color: ['#00338D', '#005EB8', '#0091DA', '#483698', '#470A68', '#6D2077', '#00A3A1', '#009A44', '#43B02A', '#EAAA00'] }
echarts.registerTheme('kpmg', theme)

export default {
  name: 'InsightsDashboard',
  created: async function () {
    this.$stat.log({ page: 'MAR dash', action: 'open MAR dash' })
    await this.load()
  },
  computed: {
    newsShow: function () {
      let result = null
      const news = this.news
      if (parseInt(this.amount) === 0) {
        result = this.news
      } else {
        const end = parseInt(this.amount)
        result = news.slice(0, end)
      }
      return result
    }
  },
  data () {
    return {
      amount: 0,
      loadingNewsRisk: true,
      news: [],
      newsRiskStartDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
      newsRiskEndDate: moment().add(1, 'days').format('YYYY-MM-DD')
    }
  },
  watch: {
    newsShow: function () {
      this.dispose()
      this.render()
    }
  },
  methods: {
    changeDate: function () {
      this.$stat.log({ page: 'MAR dash', action: 'change date', payload: { newsRiskStartDate: this.newsRiskStartDate, newsRiskEndDate: this.newsRiskEndDate } })
      this.dispose()
      this.load()
    },
    dispose: function () {
      echarts.dispose(document.getElementById('martrendchart'))
      echarts.dispose(document.getElementById('countrieschart'))
      echarts.dispose(document.getElementById('treemap'))
    },
    load: async function () {
      this.$logger.debug('loadNewsRisks started')
      this.loadingNewsRisk = true
      try {
        const response = await this.$Amplify.API.get('cosmos', `/signals/topicsfromarticles/${this.newsRiskStartDate}/${this.newsRiskEndDate}`)
        _.each(response, x => {
          x.value = x.count
        })
        this.news = response
        this.loadingNewsRisk = false
        await this.render()
      } catch (e) {
        this.$logger.warn('load ERROR: ', e)
      }
    },
    moment: function (value) {
      return moment(value)
    },
    render: async function () {
      this.$logger.debug('loadNewsRisks started')
      let news = []
      let newsStats = []
      const values = []
      const topics = []
      _.each(this.newsShow, x => {
        newsStats.push({ topic: x.name, value: x.count })
        news = _.concat(news, x.publications)
      })
      newsStats = _.sortBy(newsStats, ['value'])
      _.each(newsStats, x => {
        values.push({ value: x.value, label: { position: 'left' } })
        topics.push(x.topic)
      })
      const optionBars = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },
        grid: {
          top: 0,
          bottom: 0,
          left: '50%'
        },
        xAxis: {
          type: 'value',
          show: false,
          splitLine: { lineStyle: { type: 'dashed' } }
        },
        yAxis: {
          type: 'category',
          axisLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
          data: topics
        },
        series: [
          {
            name: 'announcements',
            type: 'bar',
            stack: 'announcements',
            label: {
              show: true,
              formatter: '{b}'
            },
            data: values
          }
        ]
      }
      const optionWheel = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: 'Countries',
            type: 'pie',
            radius: ['30%', '60%'],
            center: ['50%', '50%'],
            data: this.newsShow,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      const optionTreemap = {
        tooltip: { show: true },
        series: [
          {
            type: 'treemap',
            data: this.newsShow,
            breadcrumb: { show: false }
          }
        ]
      }
      echarts.init(document.getElementById('martrendchart'), 'kpmg').setOption(optionBars)
      echarts.init(document.getElementById('countrieschart'), 'kpmg').setOption(optionWheel)
      echarts.init(document.getElementById('treemap'), 'kpmg').setOption(optionTreemap)
    }
  }
}
</script>

<style></style>
