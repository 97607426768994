<template>
  <div class="my-4">
    <b-container>
      <b-row class="mb-4">
        <b-col>
          <b-card>
              <span>
                <b-form inline class="mb-4">
                  <label class="mr-2" for="dateInputStart">Start date:</label>
                  <b-form-datepicker id="dateInputStart" class="mr-2" type="date" v-model="startDate" required> </b-form-datepicker>
                  <label class="mr-2" for="dateInputEnd">End date:</label>
                  <b-form-datepicker id="dateInputEnd" class="mr-2" type="date" v-model="endDate" required> </b-form-datepicker>
                  <b-button variant="primary" v-on:click="changeDate">Apply</b-button>
                </b-form>
              </span>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <b-card>
            <div id="finalrulesheatmap" style="width: 100%; height: 1000px;"></div>
            <div>
              <div v-for="(topic, index) in topics" :key="'topic-label-id-' + index">
                {{index + 1}} - {{topic}}
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import * as echarts from 'echarts'
const theme = { color: ['#00338D', '#005EB8', '#0091DA', '#483698', '#470A68', '#6D2077', '#00A3A1', '#009A44', '#43B02A', '#EAAA00'] }
echarts.registerTheme('kpmg', theme)

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  name: 'MarDashboard',
  created: async function () {
    this.$stat.log({ page: 'Final rules risk dash', action: 'open Final rules risk dash' })
    await this.load()
  },
  data () {
    return {
      startDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
      endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      topics: []
    }
  },
  methods: {
    changeDate: function () {
      this.$stat.log({ page: 'Final rules risk dash', action: 'change date', payload: { startDate: this.startDate, endDate: this.endDate } })
      echarts.dispose(document.getElementById('finalrulesheatmap'))
      this.load()
    },
    load: async function () {
      this.$logger.debug('loadNewsRisks started')
      this.loadingNewsRisk = true
      try {
        const apiName = 'cosmos'
        const path = `/signals/heatmap/${this.startDate}/${this.endDate}/9`
        const response = await this.$Amplify.API.get(apiName, path)
        let topics = []
        let countries = []
        const data = []
        _.each(response, x => {
          topics.push(x.topicName)
          countries.push(x.countryCode)
        })
        _.pull(topics, 'Coronavirus', 'Cross Sector Updates')
        topics = _.reverse(_.uniq(topics))
        const topicsLabels = _.reverse(_.range(1, topics.length + 1))
        countries = _.uniq(countries)
        let topicCounter = 0
        let countryCounter = 0
        _.each(topics, topic => {
          _.each(countries, country => {
            let unit = _.find(response, { countryCode: country, topicName: topic })
            if (unit === undefined) {
              unit = { count: 0 }
            }
            data.push([countryCounter, topicCounter, _.toNumber(unit.count)])
            countryCounter = countryCounter + 1
          })
          countryCounter = 0
          topicCounter = topicCounter + 1
        })
        const options = {
          tooltip: {
            label: { formatter: '{x}' }
          },
          grid: {
            height: '80%',
            top: '0%'
          },
          xAxis: {
            type: 'category',
            data: countries,
            splitArea: {
              show: true
            }
          },
          yAxis: {
            type: 'category',
            data: topicsLabels,
            splitArea: {
              show: true
            }
          },
          visualMap: {
            min: 0,
            max: 10,
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            bottom: '5%'
          },
          series: [
            {
              name: 'frequency',
              type: 'heatmap',
              label: {
                show: true
              },
              data: data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        echarts.init(document.getElementById('finalrulesheatmap'), 'kpmg').setOption(options)

        await delay(1000)
        this.topics = _.reverse(topics)
      } catch (e) {
        this.$logger.warn('load ERROR: ', e)
      }
    },
    moment: function (value) {
      return moment(value)
    }
  }
}
</script>

<style></style>
