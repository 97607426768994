<template>
  <div class="my-4">
    <b-container>
      <b-row class="mb-4">
        <b-col>
          <b-card>
              <span>
                <b-form inline class="mb-4">
                  <label class="mr-2" for="dateInputStart">Start date:</label>
                  <b-form-datepicker id="dateInputStart" class="mr-2" type="date" v-model="newsRiskStartDate" required> </b-form-datepicker>
                  <label class="mr-2" for="dateInputEnd">End date:</label>
                  <b-form-datepicker id="dateInputEnd" class="mr-2" type="date" v-model="newsRiskEndDate" required> </b-form-datepicker>
                  <b-button variant="primary" v-on:click="changeDate">Apply</b-button>
                </b-form>
              </span>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <b-card>
            <div id="martrendchart" style="width: 100%; height: 400px;"></div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" lg="6" class="mb-4">
          <b-card>
            <div id="regulatorschart" style="width: 100%; height: 400px;"></div>
          </b-card>
        </b-col>
        <b-col md="12" lg="6" class="mb-4">
          <b-card>
            <div id="countrieschart" style="width: 100%; height: 400px;"></div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card class="mb-4">
            <span>
              <div v-if="!loadingNewsRisk" class="mb-4">
                <h4>News by market abuse risks</h4>
                <div v-for="item in news" :key="'newsRisk' + item.id">
                  <div class="mb-2">
                    <div><strong>{{ item.name }} - {{ item.publications.length }}</strong></div>
                    <div v-for="news in item.publications" :key="'newsInRisk' + news.id">
                      {{ moment(news.date).format('YYYY-MM-DD') }} - {{ news.publisher }}: <a :href="news.link" target="_blank">{{ news.name }}</a>
                    </div>
                  </div>
                </div>
              </div>
              <span v-if="loadingNewsRisk">Loading news by market risks... <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </span>

          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import * as echarts from 'echarts'
import theme from '@/libs/colorTheme'
echarts.registerTheme('kpmg', theme)

export default {
  name: 'MarDashboard',
  created: async function () {
    document.title = "MAR Dashboard"
    this.$stat.log({ page: 'MAR dash', action: 'open MAR dash' })
    await this.load()
  },
  data () {
    return {
      loadingNewsRisk: true,
      news: [],
      newsRiskStartDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
      newsRiskEndDate: moment().add(1, 'days').format('YYYY-MM-DD')
    }
  },
  methods: {
    changeDate: function () {
      this.$stat.log({ page: 'MAR dash', action: 'change date', payload: { newsRiskStartDate: this.newsRiskStartDate, newsRiskEndDate: this.newsRiskEndDate } })
      echarts.dispose(document.getElementById('martrendchart'))
      echarts.dispose(document.getElementById('countrieschart'))
      echarts.dispose(document.getElementById('regulatorschart'))
      this.load()
    },
    load: async function () {
      this.$logger.debug('loadNewsRisks started')
      this.loadingNewsRisk = true
      try {
        const apiName = 'cosmos'
        const path = `/news/risks/betweendates/${this.newsRiskStartDate}/${this.newsRiskEndDate}`
        const response = await this.$Amplify.API.get(apiName, path)
        this.news = response
        this.loadingNewsRisk = false
        let countries = []
        let news = []
        let newsStats = []
        let regulators = []
        const values = []
        const topics = []
        _.each(this.news, x => {
          newsStats.push({ topic: x.name, value: x.publications.length })
          news = _.concat(news, x.publications)
        })
        _.each(_.countBy(news, x => x.publisher), (value, key) => {
          regulators.push({ name: key, value: value })
        })
        regulators = _.sortBy(regulators, ['value'])
        _.each(_.countBy(news, x => x.countryName), (value, key) => {
          countries.push({ name: key, value: value })
        })
        countries = _.sortBy(countries, ['value'])
        newsStats = _.sortBy(newsStats, ['value'])
        _.each(newsStats, x => {
          values.push({ value: x.value, label: { position: 'left' } })
          topics.push(x.topic)
        })
        const optionTrend = {
          tooltip: {
            trigger: 'axis',
            axisPointer: { type: 'shadow' }
          },
          grid: {
            top: 0,
            bottom: 0,
            left: '50%'
          },
          xAxis: {
            type: 'value',
            show: false,
            splitLine: { lineStyle: { type: 'dashed' } }
          },
          yAxis: {
            type: 'category',
            axisLine: { show: false },
            axisLabel: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            data: topics
          },
          series: [
            {
              name: 'announcements',
              type: 'bar',
              stack: 'announcements',
              label: {
                show: true,
                formatter: '{b}'
              },
              data: values
            }
          ]
        }
        const optionCountries = {
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          series: [
            {
              name: 'Countries',
              type: 'pie',
              radius: ['30%', '60%'],
              center: ['40%', '50%'],
              data: countries,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        const optionRegulators = {
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          series: [
            {
              name: 'Regulators',
              type: 'pie',
              radius: ['30%', '60%'],
              center: ['40%', '50%'],
              data: regulators,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        echarts.init(document.getElementById('martrendchart'), 'kpmg').setOption(optionTrend)
        echarts.init(document.getElementById('countrieschart'), 'kpmg').setOption(optionCountries)
        echarts.init(document.getElementById('regulatorschart'), 'kpmg').setOption(optionRegulators)
      } catch (e) {
        this.$logger.warn('load ERROR: ', e)
      }
    },
    moment: function (value) {
      return moment(value)
    }
  }
}
</script>

<style></style>
